<template>
  <!-- Release Notes Wrapper -->
  <el-popover
    class="release-notes-wrapper"
    popper-class="release-notes"
    width="324"
    trigger="click"
    placement="bottom-end"
    :visible-arrow="false"
    @show="onShow"
    @hide="onHide"
  >
    <!-- tab -->
    <adm-tabs
      v-model="activeTab"
      :stretch-tabs="!$store.getters['settings/isAgency']"
      :class="{'single-tab': $store.getters['settings/isAgency']}"
    >
      <el-tab-pane
        name="booking-alerts"
      >
        <template #label>
          <span class="custom-tabs-label">
            <span>{{ $t('booking_alerts') }}</span>
            <span
              v-if="countNew && !$store.getters['settings/isAgency']"
              class="custom-tabs-label__count"
            >
              {{ countNew }}
            </span>
          </span>
        </template>
        <!-- Booking Alerts -->
        <div
          ref="content"
          class="release-notes__list custom-scrollbar"
        >
          <div
            v-for="({id, createdAt, description, action}, index) in getBookingAlertsFilter" :key="index"
            class="release-notes__item booking-alert"
            :class="{'new': index < countNew}"
            @click="goToAppointment(id)"
          >
            <div v-if="!loading" class="release-notes__item__content">
              <div class="release-notes__item__content__top">
                <adm-tag :type="getType(action)">
                  {{ getLabel(action) }}
                </adm-tag>
                <span class="release-notes__item__content__top__date">
                  {{ createdAt }}
                </span>
              </div>
              <div class="release-notes__item__content__title">
                {{ getTitle(action) }}
              </div>
              <div class="release-notes__item__content__description">
                {{ description }}
              </div>
            </div>
            <adm-skeleton-element v-else :height="110" />
          </div>
        </div>
        <!-- /Booking Alerts -->

        <div v-if="bookingAlerts.length === 0" class="release-notes__empty">
          {{ $t('there_is_no_booking_alerts') }}
        </div>

        <!-- Show More -->
        <transition name="fade">
          <span
            v-if="isShowMoreButtonVisible"
            class="release-notes__show-more"
            @click="showMore"
          >
            {{ $t('show_more') }}
            <adm-icon icon="arrow-down" />
          </span>
        </transition>
        <!-- Show More/ -->
      </el-tab-pane>
      <el-tab-pane
        v-if="!$store.getters['settings/isAgency']"
        name="latest-news"
      >
        <template #label>
          <span class="custom-tabs-label">
            <span>{{ $t('latest_news') }}</span>
            <span v-if="latestNewCount" class="custom-tabs-label__count"> {{ latestNewCount }} </span>
          </span>
        </template>
        <!-- Release Notes List -->
        <div class="release-notes__list custom-scrollbar">
          <div v-for="({createdAt, description, title, type}, index) in notes" :key="index" class="release-notes__item">
            <div class="release-notes__item__content">
              <div class="release-notes__item__content__top">
                <adm-tag :type="getType(type)">
                  {{ $t(type) }}
                </adm-tag>
                <span class="release-notes__item__content__top__date">
                  {{ formatDate(createdAt) }}
                </span>
              </div>
              <div class="release-notes__item__content__title">
                {{ title }}
              </div>
              <div class="release-notes__item__content__description">
                {{ description }}
              </div>
            </div>
          </div>
        </div>
        <!-- /Release Notes List -->

        <div v-if="notes.length === 0" class="release-notes__empty">
          {{ $t('there_is_no_news') }}
        </div>
      </el-tab-pane>
    </adm-tabs>

    <slot slot="reference" />
  </el-popover>
  <!-- /Release Notes Wrapper -->
</template>

<script>
import AdmTag from '@/views/_components/tag/AdmTag'
import mixinDateTime from '@/mixins/common/datetime'
import AdmTabs from '@/views/_components/tabs/AdmTabs.vue'
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'

export default {
  name: 'ReleaseNotesWrapper',
  components: {
    AdmSkeletonElement,
    AdmIcon,
    AdmTabs,
    AdmTag
  },
  mixins: [mixinDateTime],
  data () {
    return {
      notes: [],
      bookingAlerts: [],
      isNewChangelogPresented: false,
      activeTab: 'booking-alerts',
      countNew: 0,
      latestNewCount: 0,
      latestNewsTab: false,
      totalBookingAlertsCount: 0,
      showMoreFilter: 1,
      loading: false,
      showMoreButton: false,
    }
  },

  computed: {
    sumNews () {
      return this.latestNewCount + this.countNew
    },

    isLatestNewsTabOpened () {
      return this.latestNewsTab
    },

    getBookingAlertsFilter () {
      return this.bookingAlerts
    },

    isShowMoreButtonVisible () {
      return this.showMoreButton && this.totalBookingAlertsCount > 20
    }
  },

  watch: {
    activeTab(newValue) {
      if (newValue === 'latest-news') {
        this.latestNewsTab = true
        this.onShow()
      }
    }
  },

  mounted () {
    const onContentScroll = (e) => {
      if (e.target.scrollTop + e.target.offsetHeight + 192 >= e.target.scrollHeight) {
        this.showMoreButton = true

        return
      }

      if (e.target.scrollTop === 0 && this.showMoreFilter > 1) {
        this.showMoreFilter --
        this.loading = true
        this.getBookingAlerts()

        return
      }

      this.showMoreButton = false
    }

    this.$refs.content.addEventListener('scroll', onContentScroll)
  },

  created () {
    this.getAlerts()
  },

  methods: {
    async getAlerts () {
      if (!this.$store.getters['settings/isAgency']) {
        await this.getLatestNews()
      }

      await this.getBookingAlerts()
    },

    async getLatestNews () {
      this.$http.get(
        '/api/v1/backoffice/release-notes'
      ).then(({ data }) => {
        this.notes = data.list
        this.latestNewCount = data.count
        if (data.isNewChangelogPresented) {
          this.isNewChangelogPresented = true
        }
      }).catch(() => {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      })
    },

    async getBookingAlerts () {
      this.$http.get(
        '/api/v1/booking-alerts',
        {params: {
                  show: this.showMoreFilter
               }},
      ).then(({ data }) => {
        this.bookingAlerts = data.bookingAlerts
        this.countNew = data.countNew
        this.totalBookingAlertsCount = data.totalCount

        this.$emit('count-release-notes', this.sumNews)
        this.$emit('show-new-changelog-icon')
        this.loading = false
      }).catch(() => {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      })
    },

    getType (type) {
      if (type === 'bug') {
        return 'danger'
      }

      if (type === 'appointmentStatusChanged') {
        return 'danger-secondary'
      }

      if (type === 'appointmentCreated') {
        return 'positive'
      }

      if (type === 'appointmentRescheduled') {
        return 'success-secondary'
      }

      if (type === 'improvement') {
        return 'success'
      }

      if (type === 'maintenance') {
        return 'warning'
      }

      return 'primary'
    },

    getLabel (action) {
      if (action === 'appointmentStatusChanged') {
        return this.$t('booking_canceled')
      }

      if (action === 'appointmentCreated') {
        return this.$t('new_booking')
      }

      if (action === 'appointmentRescheduled') {
        return this.$t('booking_rescheduled')
      }
    },

    getTitle (action) {
      if (action === 'appointmentStatusChanged') {
        return this.$t('booking_was_canceled')
      }

      if (action === 'appointmentCreated') {
        return this.$t('you_have_one_new_booking')
      }

      if (action === 'appointmentRescheduled') {
        return this.$t('booking_was_rescheduled')
      }
    },

    onShow () {
      if (this.$store.state.guide.isGuideStepsOpened) {
        this.$store.commit('guide/closeGuideSteps')
        this.$emit('show')
      }

      if (this.isNewChangelogPresented && this.isLatestNewsTabOpened) {
        this.isNewChangelogPresented = false
        this.latestNewsTab = false
        this.$emit('show')
        this.latestNewCount = 0
        this.$http.post('/api/v1/users/employees/release-notes')
        this.onHide()
      }
      if (this.countNew) {
        this.$http.post('/api/v1/booking-alerts/last-visited')
      }
    },

    onHide () {
      this.countNew = 0
      this.$emit('count-release-notes', this.sumNews)
      this.$emit('hide')
    },

    showMore () {
      this.showMoreButton = false
      this.showMoreFilter ++
      this.loading = true
      this.getBookingAlerts()
    },

    goToAppointment (id) {
      if (this.$route.name === 'appointments') {
        this.$store.commit('view/setSearch', id)

        return
      }

      this.$router.push({ name: 'appointments', query: { id: id } })
    }
  },
}
</script>

<style lang="scss">
.release-notes {
  box-shadow: 0 11px 16px rgb(0 13 39 / 10%), 0 2px 2px rgb(0 20 60 / 17%);
  border-radius: 7px;
  padding: 0;

  @include phone-down {
    width: 100% !important;
  }

  .el-tabs__header {
    margin: 0;
  }

  .custom-tabs-label {
    display: flex;
    align-items: center;

    &__count {
      align-items: center;
      display: flex;
      font-size: 12px;
      margin-left: 4px;
      padding: 4px;
      border-radius: 6px;
      background-color: var(--primary-400);
      color: var(--primary-1000);
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      height: 16px;
    }
  }

  .single-tab {
    .el-tabs__item {
      color: $shade-900;
      font-size: 15px;
      font-weight: 500;
      padding: 12px !important;
    }

    .el-tabs__active-bar {
      display: none !important;
    }
  }

  .el-tabs__active-bar {
    width: 50% !important;
  }

  &.el-popper[x-placement^=bottom] {
    margin-top: 6px;

    @include phone-down {
      margin-top: 0;
      left: 0 !important;
      top: 56px !important;
    }
  }

  &-wrapper {
    position: relative;
  }

  &__header {
    padding: 12px;
    font-size: 15px;
    font-weight: 500;
    color: $shade-900;
    border-bottom: 1px solid $shade-300;
  }

  &__list {
    overflow-y: auto;
    max-height: 60vh;

    @media only screen and (max-width: 768px) {
      height: 100%;
      max-height: calc(100vh - 100px);
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  &__show-more {
    width: 100%;
    height: 28px;
    padding: 4px 4px 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-900);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid $shade-250;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
  }

  &__item {
    border-bottom: 1px solid $shade-250;
    transition: 0.3s;

    &.booking-alert {
      cursor: pointer;

      &:hover {
        background-color: var(--primary-300);
      }
    }

    &.new {
      background-color: var(--primary-200);
    }

    &:hover {
      background-color: $shade-150;
    }

    &__content {
      padding: 13px 16px 12px;

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        &__date {
          color: $shade-600;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }

      &__title {
        font-weight: 500;
        word-break: break-word;
        font-size: 13px;
        color: $shade-900;
        text-align: left;
        line-height: 20px;
        margin-bottom: 2px;
      }

      &__description {
        font-size: 13px;
        color: $shade-700;
        line-height: 20px;
        text-align: left;
        word-break: break-word;
      }
    }
  }
}
</style>
