import Vue from 'vue'

const getDefaultState = () => {
  return {
    billingDialogStep: 'plans',
    billingInterval: 'monthly',
    canCloseBillingDialog: true,
    currency: 'USD',
    currencySymbol: '$',
    deactivateFeatureDialog: false,
    downgradingToFree: false,
    featureDeactivated: false,
    featureToDeactivateCode: '',
    isCheckoutInProgress: false,
    limitReachedDialogEntity: '',
    limitReachedDialogText: '',
    limitReachedDialogTitle: '',
    recurringPrice: '',
    reloadPlan: false,
    selectedPlan: {},
    subtotal: 0,
    taxes: 0,
    coupon: 0,
    tenantInfo: null,
    total: 0,
    visibleDialogBilling: false,
    visibleExpireAlert: false,
    visibleLimitReachedDialog: false,
    wasSeenExpireAlert: false,
  }
}

const state = getDefaultState()

const getters = {
  isFreeTrialExpired: (state) => {
    return state.tenantInfo.tenant.isPlanChosen === false && state.tenantInfo.expirationDate && Vue.moment().isAfter(state.tenantInfo.expirationDate)
  },

  isSubscriptionPaused: (state) => {
    if (state.tenantInfo.subscription === null) {
      return false
    }

    return state.tenantInfo && state.tenantInfo.subscription && state.tenantInfo.subscription.status === 'paused'
  },

  isPlanChosen: (state) => {
    return state.tenantInfo && state.tenantInfo.tenant && state.tenantInfo.tenant.isPlanChosen
  },

  shouldDeactivateEntity: (state) => {
    const statistics = state.tenantInfo.statistics
    const currentPlan = state.tenantInfo.plan

    return (currentPlan.maxNumberOfCustomFeatures && statistics.features.value > currentPlan.maxNumberOfCustomFeatures) ||
      (currentPlan.maxNumberOfEmployees && statistics.employees.value > currentPlan.maxNumberOfEmployees)
  },

  getMaxEmployeesNumber: (state) => {
    return state?.tenantInfo?.statistics?.employees?.maxValue
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setBillingDialogStep (state, payload) {
    state.billingDialogStep = payload
  },

  setBillingInterval (state, payload) {
    state.billingInterval = payload
  },

  setCanCloseBillingDialog (state, payload) {
    state.canCloseBillingDialog = payload
  },

  setIsCheckoutInProgress (state, payload) {
    state.isCheckoutInProgress = payload
  },

  setCurrency (state, payload) {
    state.currency = payload
  },

  setCurrencySymbol (state, payload) {
    state.currencySymbol = payload
  },

  setDeactivateFeatureDialog (state, payload) {
    state.deactivateFeatureDialog = payload
  },

  setDowngradingToFree (state, payload) {
    state.downgradingToFree = payload
  },

  setFeatureDeactivated (state, payload) {
    state.featureDeactivated = payload
  },

  setFeatureToDeactivateCode (state, payload) {
    state.featureToDeactivateCode = payload
  },

  setLimitReachedDialogEntity (state, payload) {
    state.limitReachedDialogEntity = payload
  },

  setLimitReachedDialogText (state, payload) {
    state.limitReachedDialogText = payload
  },

  setLimitReachedDialogTitle (state, payload) {
    state.limitReachedDialogTitle = payload
  },

  setRecurringPrice (state, payload) {
    state.recurringPrice = payload
  },

  setReloadPlan (state, payload) {
    state.reloadPlan = payload
  },

  setSelectedPlan (state, payload) {
    state.selectedPlan = payload
  },

  setSubtotal (state, payload) {
    state.subtotal = payload
  },

  setTaxes (state, payload) {
    state.taxes = payload
  },

  setCoupon (state, payload) {
    state.coupon = payload
  },

  setTenantInfo (state, payload) {
    state.tenantInfo = payload
  },

  setTenantInfoPlan (state, payload) {
    state.tenantInfo.plan = payload
  },

  setTenantInfoStatistic (state, payload) {
    state.tenantInfo.statistics = payload
  },

  setTotal (state, payload) {
    state.total = payload
  },

  setVisibleDialogBilling (state, payload) {
    state.visibleDialogBilling = payload
  },

  setVisibleExpireAlert (state, payload) {
    state.visibleExpireAlert = payload
  },

  setVisibleLimitReachedDialog (state, payload) {
    state.visibleLimitReachedDialog = payload
  },

  setWasSeenExpireAlert (state, payload) {
    state.wasSeenExpireAlert = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
