export default [
  {
    path: 'billing',
    name: 'billing',
    redirect: { name: 'billing-plan' },
    meta: {
      title: 'Billing',
      requiresAuth: true,
      requiredPermissions: 'edit_billing_plan',
    },
    component: () => import(/* webpackChunkName: "billing" */ '@/views/Billing/BillingPage').then(value => value.default),
    children: [
      {
        path: 'plan',
        name: 'billing-plan',
        meta: {
          title: 'billing_plans',
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "billing" */ '@/views/Billing/BillingPlans/BillingPlans').then(value => value.default)
      },
      {
        path: 'history',
        name: 'billing-history',
        meta: {
          title: 'payment_history',
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "billing" */ '@/views/Billing/PaymentHistory/PaymentHistory').then(value => value.default)
      }
    ]
  }
]
