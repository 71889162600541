<template>
  <div class="share-appointment-entities">
    <div>
      {{ $isGroupOneTenant() ? 'Select the services you want to share on your website.' : $t('share_booking_form_desc') }}
    </div>

    <el-form>
      <!-- Service Category Select -->
      <adm-form-item class="mt-24" :label="$t('service_category')">
        <adm-select
          v-if="!entities['service-categories'].onCreateLoading"
          :value="$store.state.shareBookingForm.queryParams.serviceCategory"
          size="small"
          :placeholder="$t('select_service_category')"
          :loading="entities['service-categories'].loading"
          filterable
          clearable
          icon-start="category"
          value-key="id"
          @focus="() => getEntities('service-categories')"
          @change="(value) => setEntity('serviceCategory', value)"
          @clear="setEntity('serviceCategory', null)"
        >
          <adm-option
            v-for="category in entities['service-categories'].items"
            :key="category.id"
            :value="category"
            :label="category.name"
          />
        </adm-select>

        <adm-skeleton-element v-else :height="32" />
      </adm-form-item>
      <!-- /Service Category Select -->

      <!-- Service Select -->
      <adm-form-item :label="$t('service')" :class="{'mb-8': isRecurringWarningPresented()}">
        <adm-select
          v-if="!entities['services'].onCreateLoading"
          :value="$store.state.shareBookingForm.queryParams.service"
          size="small"
          :placeholder="$t('select_service')"
          :loading="entities.services.loading"
          filterable
          clearable
          icon-start="services"
          value-key="id"
          @focus="() => getEntities('services')"
          @change="(value) => setEntity('service', value)"
          @clear="setEntity('service', null)"
        >
          <adm-option
            v-for="service in entities.services.items"
            :key="service.id"
            :value="service"
            :label="service.name"
            class="justify-between"
          >
            <span>{{ service.name }}</span>
            <adm-icon v-if="service.isPrivate" type="grey" icon="eye-crossed" />
          </adm-option>
        </adm-select>

        <adm-skeleton-element v-else :height="32" />
      </adm-form-item>
      <!-- /Service Select -->

      <!-- Recurring Warning -->
      <adm-alert
        v-if="isRecurringWarningPresented()"
        type="warning" :title="$t('share_warning_booking_recurring_appointments')"
        class="mb-8"
      />
      <!-- /Recurring Warning -->

      <!-- Extras -->
      <adm-form-item
        v-if="entities?.extras?.items?.length && $store.getters['features/isFeatureEnabled']('extras')"
        :label="$t('extras')"
      >
        <div
          v-for="extra in entities.extras.items"
          :key="extra.id"
          class="share-appointment-entities__extra"
        >
          <adm-checkbox
            :value="!!isExtraSelected(extra)"
            @change="selectExtra(extra)"
          >
            {{ extra.name }}
          </adm-checkbox>

          <adm-input-number
            :min="1"
            :max="extra.maxQuantity"
            :value="getExtraQuantity(extra)"
            :disabled="!isExtraSelected(extra)"
            size="small"
            max-width="130px"
            class="share-appointment-entities__extra__quantity"
            @change="(currVal) => changeExtraQuantity(extra, currVal)"
          />
        </div>
      </adm-form-item>
      <!-- /Extras -->

      <!-- Employee Select -->
      <adm-form-item
        v-if="$store.getters['billing/getMaxEmployeesNumber'] !== 1"
        :label="$t('employee')"
      >
        <adm-select
          v-if="!entities['employees'].onCreateLoading"
          :value="$store.state.shareBookingForm.queryParams.employee"
          size="small"
          :placeholder="$t('select_employee')"
          :loading="entities.employees.loading"
          filterable
          clearable
          icon-start="employee"
          value-key="id"
          @focus="() => getEntities('employees')"
          @change="(value) => setEntity('employee', value)"
          @clear="setEntity('employee', null)"
        >
          <adm-option
            v-for="employee in entities.employees.items"
            :key="employee.id"
            :value="employee"
            :label="`${ employee.firstName } ${ employee.lastName }`"
          />
        </adm-select>

        <adm-skeleton-element v-else :height="32" />
      </adm-form-item>
      <!-- /Employee Select -->

      <!-- Location Select -->
      <adm-form-item :label="$t('location')">
        <adm-select
          v-if="!entities['locations'].onCreateLoading"
          :value="$store.state.shareBookingForm.queryParams.location"
          size="small"
          :placeholder="$t('select_location')"
          :loading="entities.locations.loading"
          filterable
          clearable
          icon-start="locations"
          value-key="id"
          @focus="() => getEntities('locations')"
          @change="(value) => setEntity('location', value)"
          @clear="setEntity('location', null)"
        >
          <adm-option
            v-for="location in entities.locations.items"
            :key="location.id"
            :value="location"
            :label="location.name"
          />
        </adm-select>

        <adm-skeleton-element v-else :height="32" />
      </adm-form-item>
      <!-- /Location Select -->

      <!-- Date Options -->
      <adm-form-item class="share-appointment-entities__date-options">
        <adm-radio-group :value="$store.state.shareBookingForm.selectedDateOption" display="flex" @input="setDateOption">
          <adm-radio
            label="daterange"
            class="share-appointment-entities__date-options__date-range"
          >
            {{ $t('use_date_range') }}
          </adm-radio>
          <adm-radio
            label="timeslots"
          >
            {{ $t('set_specific_timeslots') }}
          </adm-radio>
        </adm-radio-group>
      </adm-form-item>
      <!-- /Date Options -->

      <!-- Date & Time Pickers -->
      <date-time-section />
      <!-- /Date & Time Pickers -->
    </el-form>
  </div>
</template>

<script>
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox'
import DateTimeSection from './DateTimeSection/DateTimeSection'
import AdmOption from '@/views/_components/select/AdmOption'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmInputNumber from '@/views/_components/input/AdmInputNumber'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmRadioGroup from '@/views/_components/radio/AdmRadioGroup.vue'
import AdmRadio from '@/views/_components/radio/AdmRadio.vue'
import AdmAlert from '@/views/_components/alert/AdmAlert.vue'
import { mapState } from 'vuex'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'

export default {
  name: 'LinkEntitiesSection',

  components: {
    AdmSkeletonElement,
    AdmAlert,
    AdmRadio,
    AdmRadioGroup,
    AdmIcon,
    AdmInputNumber,
    AdmCheckbox,
    DateTimeSection,
    AdmOption,
    AdmFormItem,
    AdmSelect,
  },

  data: function () {
    return {
      acceptedUrlParams: ['serviceCategory', 'service', 'employee', 'location'],

      entities: {
        'service-categories': {
          items: [],
          loading: false,
          onCreateLoading: false,
        },
        services: {
          items: [],
          loading: false,
          onCreateLoading: false,
        },
        employees: {
          items: [],
          loading: false,
          onCreateLoading: false,
        },
        locations: {
          items: [],
          loading: false,
          onCreateLoading: false,
        },
        extras: {
          items: [],
          loading: false,
        },
      },
    }
  },

  computed: {
    ...mapState({
      queryParams: state => state.shareBookingForm.queryParams,
    }),
  },

  watch: {
    'queryParams.service': function (newValue) {
      if (newValue?.extras) {
        this.entities.extras.items = newValue.extras

        return
      }

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'extras', value: []
      })
      this.entities.extras.items = []
    }
  },

  async created () {
    for (const [entityType, value] of Object.entries(this.$store.state.shareBookingForm.queryParams)) {
      if (this.acceptedUrlParams.includes(entityType) && value) {
        if (entityType === 'service') {
          await this.getEntities(this.getSingularToPluralEntityName(entityType), true)

          if (this.entities.services?.items[0]) {
            this.setEntity('service', this.entities.services?.items[0], false)
          }
        } else {
          this.getEntities(this.getSingularToPluralEntityName(entityType), true)
        }
      }
    }
  },

  methods: {
    getUrlParams () {
      let result = [], key
      const queryParams = { ...this.queryParams }

      for (key in queryParams) {
        if (this.acceptedUrlParams.includes(key) && queryParams[key]) {
          result[key] = queryParams[key]
        }
      }

      if (this.queryParams.extras.length) {
        result['extras'] = JSON.stringify([...this.queryParams.extras.map(extra => {
          return {
            id: extra.id,
            name: extra.name,
            quantity: extra.quantity
          }
        })])
      }

      if (this.queryParams.dateRange) {
        result['dateRange'] = JSON.stringify(this.queryParams.dateRange)
      }

      if (this.queryParams.timeslots) {
        result['timeslots'] = this.queryParams.timeslots
      }

      return result
    },

    selectExtra (extra) {
      const itemIndex = this.queryParams.extras.findIndex(selectedExtra => selectedExtra.id === extra.id)

      if (itemIndex !== -1) {
        this.queryParams.extras = this.queryParams.extras.filter(selectedExtra => selectedExtra.id !== extra.id)

        return
      }

      if (extra.duration) {
        this.removeTimeslotsAndNotify()
      }

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'extras', value: [
          ...this.queryParams.extras,
          { ...extra, checked: true, quantity: 1 }
        ]
      })
    },

    changeExtraQuantity (extra, value) {
      const extraIndex = this.$store.state.shareBookingForm.queryParams.extras.findIndex(selectedExtra => selectedExtra.id === extra.id)

      if (extra.duration) {
        this.removeTimeslotsAndNotify()
      }

      if (extraIndex > -1) {
        this.$store.commit('shareBookingForm/setExtraQuantity', {
          index: extraIndex, value
        })

        return
      }

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'extras', value: [...this.queryParams.extras, { ...extra, quantity: value }]
      })
    },

    getExtraQuantity (extra) {
      const selectedExtra = this.queryParams.extras.find(selectedExtra => selectedExtra.id === extra.id)

      return (selectedExtra && selectedExtra.checked && selectedExtra.quantity) ? selectedExtra.quantity : undefined
    },

    isExtraSelected (extra) {
      return this.queryParams.extras.find(selectedExtra => selectedExtra.id === extra.id && selectedExtra.checked)
    },

    getEntities (entityType, onCreate) {
      this.entities[entityType].loading = true
      this.entities[entityType].onCreateLoading = !!onCreate

      const requestParams = {
        serviceCategory: this.queryParams.serviceCategory?.id,
        service: this.queryParams.service?.id,
        employee: this.queryParams.employee?.id,
        location: this.queryParams.location?.id,
        frontend: true,
        serviceWasPredefined: true,
      }

      return this.$http.get(
        `/api/v1/appointments/entities/${ entityType }`,
        { params: requestParams }
      ).then(response => {
        this.entities[entityType].items = response.data.payload
      }).finally(() => {
        this.entities[entityType].loading = false
        this.entities[entityType].onCreateLoading = false
      })
    },

    setEntity (entityType, value, resetSlots = true) {
      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType, value
      })

      if (value && resetSlots) {
        this.removeTimeslotsAndNotify()
      }
    },

    getSingularToPluralEntityName (singular) {
      const entityMap = {
        serviceCategory: 'service-categories',
        service: 'services',
        employee: 'employees',
        location: 'locations',
      }

      if (entityMap.hasOwnProperty(singular)) {
        return entityMap[singular]
      }

      return singular
    },

    setDateOption (val) {
      this.$store.commit('shareBookingForm/setSelectedDateRangeOption', val)
      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'timeslots', value: [{date: null, time: null}]
      })

      if (val === 'timeslots') {
        this.$store.commit('shareBookingForm/setQueryParams', {
          entityType: 'dateRange', value: null
        })
      }
    },

    removeTimeslotsAndNotify () {
      const storeTimeslots = this.$store.state.shareBookingForm.queryParams.timeslots

      if (this.$store.state.shareBookingForm.selectedDateOption === 'daterange' ||
        (storeTimeslots.length === 1
        && storeTimeslots[0].date === null
        && storeTimeslots[0].time === null)
      ) {
        return
      }

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'timeslots', value: []
      })
      this.$nextTick(_ => {
        this.$store.commit('shareBookingForm/setQueryParams', {
          entityType: 'timeslots', value: [{date: null, time: null}]
        })
      })

      this.$message({
        message: this.$t('timeslots_removed'),
        type: 'warning',
        showClose: true,
        duration: 6000
      })
    },

    isRecurringWarningPresented () {
      return this.$store.getters['features/isFeatureEnabled']('recurring_appointments')
        && this.$store.state.shareBookingForm.selectedDateOption === 'timeslots'
        && !this.queryParams.service
    }
  }
}
</script>

<style scoped lang="scss">
.share-appointment-entities {
  &__extra {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__quantity {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  &__date-options {
    &__date-range {
      margin-right: 12px;
    }
  }
}
</style>
