export default [
  {
    path: 'services',
    name: 'services',
    meta: {
      title: 'services',
      requiresAuth: true,
      requiredPermissions: 'read_service',
    },
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/ServicesList/ServicesList.vue').then(value => value.default)
  },
  {
    path: 'services/:serviceId(\\d+)',
    name: 'services-edit',
    redirect: { name: 'service-appearance' },
    meta: {
      title: 'edit_service',
      requiresAuth: true,
      requiredPermissions: 'edit_service',
    },
    component: () => import(/* webpackChunkName: "services-manage" */ '@/views/Services/ManageService/ManageService').then(value => value.default),
    children: [
      {
        path: 'appearance',
        name: 'service-appearance',
        meta: {
          title: 'service_appearance',
          requiresAuth: true,
          requiredPermissions: 'edit_service',
        },
        component: () => import(/* webpackChunkName: "services-manage" */ '@/views/Services/ManageService/ServiceAppearance/ServiceAppearance').then(value => value.default)
      },
      {
        path: 'settings',
        name: 'service-settings',
        meta: {
          title: 'service_settings',
          requiresAuth: true,
          requiredPermissions: 'edit_service',
        },
        component: () => import(/* webpackChunkName: "services-manage" */ '@/views/Services/ManageService/ServiceSettings/ServiceSettings').then(value => value.default)
      },
      {
        path: 'extras',
        name: 'service-extras',
        meta: {
          title: 'service_extras',
          requiresAuth: true,
          requiredPermissions: 'edit_service',
          feature: 'extras',
        },
        component: () => import(/* webpackChunkName: "services-manage" */ '@/views/Services/ManageService/ServiceExtras/ServiceExtras').then(value => value.default)
      },
      {
        path: 'gallery',
        name: 'service-gallery',
        meta: {
          title: 'service_gallery',
          requiresAuth: true,
          requiredPermissions: 'edit_service',
        },
        component: () => import(/* webpackChunkName: "customize-general" */ '@/views/Services/ManageService/ServiceGallery/ServiceGallery').then(value => value.default)
      },
    ]
  }
]
