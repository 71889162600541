import en from 'element-ui/lib/locale/lang/en'
import es from 'element-ui/lib/locale/lang/es'
import cs from 'element-ui/lib/locale/lang/cs-CZ'
import ptbr from 'element-ui/lib/locale/lang/pt-br'
import fr from 'element-ui/lib/locale/lang/fr'
import de from 'element-ui/lib/locale/lang/de'
import sr from './assets/element-sr-Latn'

export default {
  en,
  es,
  cs,
  fr,
  de,
  'sr-Latn': sr,
  'pt-BR': ptbr
}
