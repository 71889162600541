import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementLocales from './plugins/elementLocales'

Vue.use(VueI18n)

document.documentElement.lang = window.language === 'sr-Latn' ? 'sr' : window.language

function loadLocaleMessages () {
  const locales = require.context('../../../backend/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {...elementLocales[locale], ...locales(key)}
    }
  })

  return messages
}
export default new VueI18n({
  locale: window.language,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),

  // Key - language to use the rule for, `'sr-Latn'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'sr-Latn': (choice, choicesLength) => {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
})
