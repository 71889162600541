<template>
  <div class="adm-editor" :style="minHeight ? `min-height: ${minHeight}px` : undefined">
    <quill-editor
      ref="quill-editor"
      :content="model"
      :options="options"
      bounds=".adm-editor"
      @keydown.enter.native="increaseQuillCursorIndex"
      @input="(eventValue) => saveQuillCursorIndex(eventValue)"
      @focus="(eventValue) => saveQuillCursorIndex(eventValue)"
    />
    <adm-input
      v-show="showHtmlEditor"
      ref="quill-html-editor"
      v-model="selfHtml"
      class="quill-html-editor"
      type="textarea"
      :autosize="{ minRows: 12 }"
      @change="saveHtmlContent"
    />
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { Quill } from 'vue-quill-editor/src'
import AdmInput from '@/views/_components/input/AdmInput.vue'

const colors = [ "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466" ]

export default {
  name: 'AdmEditor',

  components: {
    AdmInput,
    quillEditor
  },

  props: {
    value: {
      type: [String, Object],
      default: ''
    },

    minHeight: {
      type: Number,
      default: null
    },

    editorOptions: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    limit: {
      type: Number,
      default: null
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      selfModel: '',
      selfHtml: '',
      showHtmlEditor: false,
      quillCursorIndex: 0,
      styleToRegister: [
        {
          style: 'display',
          whitelist: ['inline', 'flex', 'block', 'none']
        },
        {
          style: 'float',
          whitelist: ['left', 'right']
        },
        {
          style: 'margin',
          whitelist: null
        },
        {
          style: 'width',
          whitelist: null
        },
        {
          style: 'height',
          whitelist: null
        },
        {
          style: 'padding',
          whitelist: null
        },
      ],
      attributorsToRegister: [
        'attributors/style/background',
        'attributors/style/color',
        'attributors/style/align',
        'attributors/style/direction',
        'attributors/style/font',
        'attributors/style/font-size',
      ],
      htmlEditorKey: 0,
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    },

    options: function () {
      if (this.editorOptions && Object.keys(this.editorOptions).length === 0) {
        return {
          placeholder: this.placeholder ? this.placeholder : this.$t('insert_text_here'),
          modules: {
            toolbar: false
          }
        }
      }

      if (this.editorOptions) {
        return {...this.editorOptions, placeholder: this.placeholder ? this.placeholder : this.$t('insert_text_here')}
      }

      return {
        placeholder: this.placeholder ? this.placeholder : this.$t('insert_text_here'),
        modules: {
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ color: colors }, { background: colors }],
              [{ align: [] }],
              ['clean'],
              ['link'],
              ['undo'],
              ['html']
            ],
            handlers: {
              undo() {
                this.quill.history.undo()
              }
            }
          }
        }
      }
    }
  },

  watch: {
    model: {
      immediate: true,
      handler (value) {
        if (this.$refs['quill-editor']) {
          if (this.hasHtmlEditorOption()) {
            this.setHtmlEditor()
          }

          this.setBackgroundForWhiteText(value)
        }
      }
    }
  },

  created () {
    const icons = Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
      <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
      <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>`;

    const Parchment = Quill.import('parchment');

    // register style attributors
    this.attributorsToRegister.forEach((attributor) => {
      const AttributorClass = Quill.import(attributor);
      Quill.register(AttributorClass, true);
    })

    // register custom style
    this.styleToRegister.forEach((style) => {
      Quill.register(new Parchment.Attributor.Style(style.style, style.style, {
        whitelist: style.whitelist
      }));
    })
  },

  mounted () {
    if (this.hasHtmlEditorOption()) {
      const htmlButton = document.querySelectorAll('.ql-html');
      htmlButton.forEach((button, index) => {
        this.htmlEditorKey = index
        button.classList.add('html-btn-' + this.htmlEditorKey)
        button.addEventListener('click', this.setHtmlEditor)
      })
    }

    this.setBackgroundForWhiteText(this.value)

    this.$refs['quill-editor'].quill.clipboard.addMatcher('a', function(node, delta) {
      delta.ops = delta.ops.map(op => {
        let link = decodeURI(op?.attributes?.link)

        const newOp = {
          insert: op.insert,
          attributes: {
            link
          }
        }

        if (link) {
          const placeholder = [
            '{{appointment_cancel_link}}',
            '{{appointment_cancel_link_short}}',
            '{{appointment_reschedule_link}}',
            '{{appointment_reschedule_link_short}}'
          ].filter(placeholder => {
            return link.includes(placeholder)
          })

          if (placeholder.length) {
            link = placeholder[0]
          }

          newOp.attributes.link = link
        }

        return newOp
      })

      return delta
    })

    if (this.disabled) {
      this.$refs['quill-editor'].quill.enable(false)
    }
  },

  methods: {
    hasHtmlEditorOption() {
      if (this.editorOptions) {
        return this.editorOptions.modules.toolbar.container.filter(option => option[0] === 'html').length
      }

      if (this.options) {
        return this.options.modules.toolbar.container.filter(option => option[0] === 'html').length
      }

      return false
    },

    setHtmlEditor (button = null) {
      if (button && button.target.classList.contains('html-btn-' + this.htmlEditorKey)) {
        this.showHtmlEditor = !this.showHtmlEditor
      }

      if (!button) {
        this.showHtmlEditor = false
      }

      this.selfHtml = this.$refs['quill-editor'].quill.root.innerHTML.replace(/</g, '\n<').replace(/>/g, '>\n').trim()
      this.$refs['quill-editor'].$refs.editor.style.display = !this.showHtmlEditor || !button ? 'block' : 'none'
      const htmlButton = button ? button.target : document.querySelector('.ql-html')
      htmlButton.classList.add('ql-html--active')

      if (!this.showHtmlEditor || !button) {
        htmlButton.classList.remove('ql-html--active')
      }
    },

    saveHtmlContent () {
      this.$refs['quill-editor'].quill.setContents('')
      this.$refs['quill-editor'].quill.clipboard.dangerouslyPasteHTML(0, this.selfHtml.replace('\n', ''));
    },

    saveQuillCursorIndex (value) {
      if (this.limit && this.$refs['quill-editor'].quill.getLength() >= this.limit) {
        this.$refs['quill-editor'].quill.deleteText(this.limit-1, this.$refs['quill-editor'].quill.getLength());
        value = this.$refs['quill-editor'].quill.root.innerHTML
      }

      if (this.$refs['quill-editor'].quill.getSelection() !== null) {
        this.quillCursorIndex = this.$refs['quill-editor'].quill.getSelection().index
      }

      if (typeof value === 'string') {
        this.$emit('input', value)
      }
    },

    increaseQuillCursorIndex () {
      this.quillCursorIndex = this.quillCursorIndex + 1
    },

    setBackgroundForWhiteText (value) {
      if (value.includes('color: rgb(255, 255, 255)')) {
        this.$refs['quill-editor'].$refs.editor.style.backgroundColor = '#CCD0D8'
      } else {
        this.$refs['quill-editor'].$refs.editor.style.backgroundColor = '#FFFFFF'
      }
    }
  }
}
</script>

<style lang="scss">
.adm-editor {
  .quill-html-editor {
    textarea {
      border-top: none;
      border-radius: 0 0 7px 7px;
    }
  }

  .quill-editor {
    .ql-html:hover:before {
      color: #06c;
    }

    .ql-tooltip {
      z-index: 10;
      white-space: normal;
      a.ql-action:after {
        margin-left: 8px !important;
      }
    }

    .ql-html:before {
      content: "</>";
      display: inline-block;
      line-height: 22px;
      border: 1px solid transparent;
      color: #444444;
      font-size: 14px;
      font-weight: 500;
    }

    button.ql-html {
      height: 26px;
      padding: 0;

      &--active:before {
        color: #06c;
      }
    }

    .ql-toolbar {
      border-color: $shade-300;
      border-radius: 7px 7px 0 0;
    }

    .ql-container {
      border-color: $shade-300;
      border-radius: 0 0 7px 7px;
      min-height: inherit;

      .ql-editor {
        height: 100%;
      }
    }
  }
}
</style>
