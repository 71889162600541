<template>

  <!-- Billing Dialog Plans -->
  <div class="ad-billing-dialog-sms">

    <!-- Title & Billing Interval -->
    <div class="ad-billing-dialog-sms__header">

      <!-- Title -->
      <div class="ad-billing-dialog-sms__header__title">
        <span>
          {{ $t('add_more_sms_credits') }}
        </span>
      </div>
      <!-- /Title -->

    </div>
    <!-- /Title & Billing Interval -->

    <!-- Billing Alert Info -->
    <adm-alert class="mt-20">
      <template #description>
        {{ $t('sms_credits_pricing_desc') }}
      </template>
    </adm-alert>
    <!-- /Billing Alert Info -->

    <!-- Plans -->
    <div class="ad-billing-dialog-sms__plans">
      <div
        v-for="plan in plans"
        :key="plan.paddleId"
        class="ad-billing-dialog-sms__plans__item"
      >
        <div class="ad-billing-dialog-sms__plans__item__left">
          <img class="mr-16" :src="$getDashboardImageLink('features', 'sms.svg')">
          <span>
            <span class="ad-billing-dialog-sms__plans__item__left__credits">
              {{ plan.credits }}
            </span> {{ $t('sms_credits') }}
          </span>
        </div>
        <div class="ad-billing-dialog-sms__plans__item__right">
          <adm-button v-if="!loadingPrices" class="ad-billing-dialog-sms__plans__item__right__button" size="medium" @click="selectSmsPlan(plan)">
            {{ formatPlanPrice(plan.price.net) }} - {{ $t('select') }}
          </adm-button>
          <adm-skeleton-element v-if="loadingPrices" :height="36" :width="120" />
        </div>
      </div>
    </div>
    <!-- /Plans -->

  </div>
  <!-- /Billing Dialog Plans -->

</template>

<script>
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import mixinPrice from '@/mixins/common/price'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'BillingDialogSms',

  components: {
    AdmAlert,
    AdmButton,
    AdmSkeletonElement,
  },

  mixins: [
    mixinPrice
  ],

  data () {
    return {
      loadingPrices: false,
      plans: [
        {
          name: this.$t('10_sms_credits'),
          credits: 10,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_10,
        },
        {
          name: this.$t('20_sms_credits'),
          credits: 20,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_20
        },
        {
          name: this.$t('50_sms_credits'),
          credits: 50,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_50
        },
        {
          name: this.$t('100_sms_credits'),
          credits: 100,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_100
        },
        {
          name: this.$t('200_sms_credits'),
          credits: 200,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_200
        },
        {
          name: this.$t('500_sms_credits'),
          credits: 500,
          price: null,
          paddleId: process.env.VUE_APP_PADDLE_SMS_500
        }
      ]
    }
  },

  computed: {
    ...mapState({
      billingInterval: state => state.billing.billingInterval,
      tenantInfo: state => state.billing.tenantInfo
    }),

    ...mapGetters('billing', [
      'isSubscriptionPaused',
      'isFreeTrialExpired'
    ]),
  },

  created () {
    document.documentElement.style.overflowY = 'hidden'
    this.getPrices()
  },

  destroyed () {
    document.documentElement.style.overflowY = null
  },

  methods: {
    async getPrices () {
      this.loadingPrices = true
      const response = await this.$jsonp(
        process.env.VUE_APP_PADDLE_SANDBOX_ENABLED === '1' ? 'https://sandbox-checkout.paddle.com/api/2.0/prices' : 'https://checkout.paddle.com/api/2.0/prices',
        { product_ids: this.getSmsPlansPaddleIds().join(', ') }
      )

      if (response.success === true && response.response.products.length > 0) {
        this.paddleSmsProducts = response.response.products
        for (let i = 0; i < this.plans.length; i++) {
          this.plans[i].price = this.paddleSmsProducts.find(paddleProduct => paddleProduct.product_id.toString() === this.plans[i].paddleId).price
        }
        this.$store.commit('billing/setCurrency', response.response.products[0].currency)
        this.$store.commit('billing/setCurrencySymbol', this.getCurrencySymbolByCountryCode(response.response.products[0].currency))
        this.loadingPrices = false
      }
    },

    getSmsPlansPaddleIds () {
      return [
        process.env.VUE_APP_PADDLE_SMS_10,
        process.env.VUE_APP_PADDLE_SMS_20,
        process.env.VUE_APP_PADDLE_SMS_50,
        process.env.VUE_APP_PADDLE_SMS_100,
        process.env.VUE_APP_PADDLE_SMS_200,
        process.env.VUE_APP_PADDLE_SMS_500,
      ]
    },

    selectSmsPlan (plan) {
      this.$emit('change-step', 'checkout')
      plan.isSmsPlan = true
      this.$store.commit('billing/setSelectedPlan', plan)
    }
  },
}
</script>

<style lang="scss">
// Billing Dialog Plans
.ad-billing-dialog-sms {
  max-width: 484px;
  margin: 0 auto;

  // Header
  &__header {
    display: flex;
    align-items: flex-end;
    margin: 32px auto 0;

    // Title
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
    }
  }

  // Plans
  &__plans {
    margin: 24px auto 0;
    padding-bottom: 60px;

    // Item
    &__item {
      background-color: $adm-white;
      box-shadow: 0 1px 1px rgba(0, 20, 60, 0.21), 0 2px 7px -1px rgba(0, 0, 0, 0.12);
      padding: 1rem 1.25rem;
      margin-bottom: 0.75rem;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px;

      & > * {
        flex: 1;
      }

      // Last Item
      &:last-of-type {
        margin-bottom: 0;
      }

      // Left Side
      &__left {
        display: flex;
        align-items: center;
        width: 220px;

        // Credits
        &__credits {
          font-weight: 600;
        }

        // Span
        span {
          font-size: 18px;
        }
      }

      &__right {
        &__button {
          width: 100%;
        }
      }
    }
  }
}
</style>
