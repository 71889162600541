const moment = require('moment')

if (window.language === 'sr-Latn') {
  require('moment/locale/sr')
}

if (window.language === 'pt-BR') {
  require('moment/locale/pt-br')
}

export default moment
