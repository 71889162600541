import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard/Dashboard'
import store from '@/store'
import Cookies from 'js-cookie'
import i18n from '@/i18n'
import { isFeatureEnabled, isFeatureAvailableInPlan } from '@/mixins/features/features'
import { SUPER_ADMIN_SLUG } from '@/mixins/security/security'
import settingsRoutes from '@/router/routes/settingsRoutes'
import billingRoutes from '@/router/routes/billingRoutes'
import customizeRoutes from '@/router/routes/customizeRoutes'
import financeRoutes from '@/router/routes/financeRoutes'
import locationRoutes from '@/router/routes/locationRoutes'
import serviceRoutes from '@/router/routes/serviceRoutes'
import customerRoutes from '@/router/routes/customerRoutes'
import employeeRoutes from '@/router/routes/employeeRoutes'
import appointmentRoutes from '@/router/routes/appointmentRoutes'
import accountRoutes from '@/router/routes/accountRoutes'
import designSystemRoutes from '@/router/routes/designSystemRoutes'
import guestRoutes from '@/router/routes/guestRoutes'
import sharedRoutes from '@/router/routes/sharedRoutes'
import guideRoutesMapping from '@/views/Guide/guideRoutesMapping'
import featuresIntegrationsRoutes from '@/router/routes/features&integrationsRoutes'
import ratingReviewRoutes from '@/router/routes/ratingReviewRoutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes: [
    ...designSystemRoutes,
    ...guestRoutes,
    ...sharedRoutes,
    {
      path: '/404',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound/NotFound').then(value => value.default)
    },
    {
      path: '',
      name: 'defaultRoute',
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/',
      name: 'home',
      meta: {
        requiresAuth: true,
      },
      component: Dashboard,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          meta: {
            title: 'dashboard',
            requiresAuth: true,
            requiredPermissions: 'read_dashboard',
          },
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardPage/DashboardPage').then(value => value.default)
        },
        {
          path: 'calendar',
          name: 'calendar',
          meta: {
            title: 'calendar',
            requiresAuth: true,
            requiredPermissions: 'read_calendar',
          },
          component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar/CalendarPage').then(value => value.default)
        },
        ...appointmentRoutes,
        ...employeeRoutes,
        ...customerRoutes,
        ...serviceRoutes,
        ...locationRoutes,
        ...financeRoutes,
        ...settingsRoutes,
        ...billingRoutes,
        ...accountRoutes,
        ...customizeRoutes,
        ...featuresIntegrationsRoutes,
        ...ratingReviewRoutes,
        {
          path: '*',
          name: 'NotFound',
          meta: {
            title: 'Not Found'
          },
          component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound/NotFound').then(value => value.default)
        }
      ]
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const activePopoverName = store.getters['guide/getActivePopoverName']

  if (store.state.guide.isGuideStepsOpened) {
    store.commit('guide/closeGuideSteps')
  }

  if (activePopoverName) {
    if (
      guideRoutesMapping[activePopoverName] &&
      guideRoutesMapping[activePopoverName].routes.includes(to.name) &&
      store.state.guide.popover[activePopoverName]
    ) {
      store.commit('guide/openItemPopover', guideRoutesMapping[activePopoverName].nextPopover)
    }

    let isClosePopoverNeeded = true
    if (
      activePopoverName === 'employeesAssignedServiceTab'
      && (to.name === 'employees-edit')
    ) {
      isClosePopoverNeeded = false
    }

    if (isClosePopoverNeeded) {
      store.commit('guide/closeItemPopover', activePopoverName)
    }
  }

  // Log in user on initialization
  if (to.query.accessToken) {
    router.app.$cookies.set('access_token', { token: to.query.accessToken }, '0', '/', '', process.env.VUE_APP_SOURCE_PROTOCOL === 'https://')
    let userData = null
    await Promise.all([
      router.app.$http.get('/api/v1/users/current'),
      router.app.$http.get('/api/v1/settings'),
      router.app.$http.get('/api/v1/settings/features/status'),
      router.app.$http.get('/api/v1/users/permissions')
    ]).then(async ([userResponse, settingsResponse, featuresResponse, permissionsResponse]) => {
      userData = userResponse.data.user
      store.commit('features/setFeatures', featuresResponse.data.features)
      store.commit('settings/setSettings', settingsResponse.data.settings)
      store.commit('user/setUser', userData)
      store.commit('user/setPermissions', permissionsResponse.data.permissions)
      store.commit('user/setAllEntitiesPermissions', permissionsResponse.data.allEntitiesPermissions)
    })

    if (to.query.isAppSumo) {
      store.dispatch('settings/setGuidePresentedInitially')
      return next({ name: 'billing-plan', query: {} })
    }
  }

  if (to.name === 'home' || to.name === 'defaultRoute') {
    to.meta.title = store.state.settings.general.defaultFirstPage
    return next({ name: store.state.settings.general.defaultFirstPage })
  }

  const title = store.getters['settings/isAgency'] && store.state.settings.agency.agencyName
    ? store.state.settings.agency.agencyName
    : 'Trafft'

  document.title = to.meta.title ? title + ' | ' + i18n.t(to.meta.title) : title

  if (to.matched.some(record => record.meta.requiresAuth === true)) {
    if ((!!Cookies.get('access_token') === false && localStorage.getItem('access_token') === null)) {
      if (to.name !== 'NotFound' && to.name !== 'dashboard') {
        sessionStorage.setItem('redirectUrl', to.fullPath)
      }

      return next({ name: 'login', query: to.query, params: { nextUrl: to.fullPath } })
    }

    if (to.meta) {
      const userPermissions = store.state.user.permissions

      // Check access to a route depends on certain permission
      let requiredPermissions = to.meta.requiredPermissions

      if (requiredPermissions) {
        if (typeof requiredPermissions === 'string') {
          requiredPermissions = [requiredPermissions]
        }

        // Redirect to the first page user has an access
        if (!requiredPermissions.filter(requiredPermission => userPermissions.includes(requiredPermission)).length) {
          for (const route of router.options.routes[0].children) {

            // Check the route
            if (route.meta) {
              let routeMetaRequiredPermissions = route.meta.requiredPermissions

              if (routeMetaRequiredPermissions) {
                if (typeof routeMetaRequiredPermissions === 'string') {
                  routeMetaRequiredPermissions = [routeMetaRequiredPermissions]
                }

                if (routeMetaRequiredPermissions.filter(routeMetaRequiredPermission => userPermissions.includes(routeMetaRequiredPermission)).length) {
                  return router.push({ name: route.name })
                }
              }
            }

            // Check route children
            if (!route.meta && route.children) {
              for (const childrenRoute of route.children) {
                if (childrenRoute.meta) {
                  let routeMetaRequiredPermissions = childrenRoute.meta.requiredPermissions

                  if (routeMetaRequiredPermissions) {
                    if (typeof routeMetaRequiredPermissions === 'string') {
                      routeMetaRequiredPermissions = [routeMetaRequiredPermissions]
                    }

                    if (routeMetaRequiredPermissions.filter(routeMetaRequiredPermission => userPermissions.includes(routeMetaRequiredPermission)).length) {
                      return router.push({ name: childrenRoute.name })
                    }
                  }
                }
              }
            }
          }

          return next({ name: 'NotFound' })
        }
      }
    }

    // Checks if feature is active, available in plan or route exists
    if ((to.meta.feature && !isFeatureEnabled(to.meta.feature, store)) ||
      (to.meta.planFeature && !isFeatureAvailableInPlan(to.meta.planFeature, store)) ||
      !to.matched.length
    ) {
      return next({ name: 'NotFound' })
    }

    if (!isFeatureAvailableInPlan('users_and_roles_page', store) && !store.state.user.roles.some(role => role.code === SUPER_ADMIN_SLUG)) {
      router.app.$cookies.remove('access_token')
      localStorage.removeItem('access_token')
      store.commit('user/resetState')

      return next({ name: 'login' })
    }

    store.commit('billing/setVisibleLimitReachedDialog', false)

    if (to.query.accessToken) {
      return next({ name: to.name, query: {} })
    }

    return next()
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!!store.state.user.email === false || (!!Cookies.get('access_token') === false && localStorage.getItem('access_token') === null)) {
      return next()
    }

    if (to.query.fcmToken) {
      router.app.$http.post('/api/v1/users/fcm-registration-token', {
        token: to.query.fcmToken
      })
    }

    next({ name: 'home' })
  } else if (to.matched.some(record => record.meta.shared)) {
    next()
  } else {
    next()
  }
})

export default router
