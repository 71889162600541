<template>
  <adm-modal
    v-if="visibleDialogBilling"
    :close-visible="false"
    :visible="visibleDialogBilling"
    class="ad-billing-dialog"
    fullscreen
    @close="closeDialog"
    @keyup.esc="closeDialog"
  >

    <!-- Logo & Close Dialog Button -->
    <div
      class="ad-billing-dialog__toolbar"
      :style="{ maxWidth: $screen.aboveMobile ? toolbarMaxWidth + 'px' : 'auto' }"
    >

      <!-- Logo -->
      <img v-if="!$store.getters['settings/isAgency']" width="120" src="@/assets/img/logo/logo-full.svg">
      <!-- /Logo -->

      <!-- Close Dialog Button -->
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="canCloseBillingDialog === true" v-bind:svg-inline="''" v-bind:class="'ad-billing-dialog__toolbar__close'" @click="closeDialog" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M13.41 12l4.3-4.29a1.004 1.004 0 10-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 00.325 1.639 1 1 0 001.095-.219l4.29-4.3 4.29 4.3a1.002 1.002 0 001.639-.325 1 1 0 00-.219-1.095L13.41 12z" fill="#005AEE"/></svg>
      <!-- /Close Dialog Button -->

    </div>
    <!-- /Logo & Close Dialog Button -->

    <!-- Billing Dialog Plans -->
    <billing-dialog-plans
      v-if="billingDialogStep === 'plans'"
      :loading="loading"
      :plans="plans"
      @change-step="onChangeStep"
      @contact-us="$emit('contact-us')"
    />
    <!-- /Billing Dialog Plans -->

    <!-- Billing Dialog Checkout -->
    <billing-dialog-checkout
      v-if="billingDialogStep === 'checkout'"
      :items-to-deactivate="itemsToDeactivate"
      @change-plan="onChangePlan"
      @change-step="onChangeStep"
    />
    <!-- /Billing Dialog Checkout -->

    <!-- Billing Dialog Free Downgrade -->
    <billing-dialog-free-downgrade
      v-if="billingDialogStep === 'free-downgrade'"
      @change-step="onChangeStep"
    />
    <!-- Billing Dialog Free Downgrade -->

    <!-- Billing Dialog Free Chosen -->
    <billing-dialog-free-chosen
      v-if="billingDialogStep === 'free-chosen'"
      @change-step="onChangeStep"
    />
    <!-- Billing Dialog Free Chosen -->

    <!-- Billing Dialog Deactivate -->
    <billing-dialog-deactivate
      v-if="billingDialogStep === 'deactivate'"
      :items-to-deactivate="itemsToDeactivate"
      @change-step="onChangeStep"
      @update-deactivation-items="updateDeactivationItems"
    />
    <!-- Billing Dialog Deactivate -->

    <!-- Billing Dialog SMS -->
    <billing-dialog-sms
      v-if="billingDialogStep === 'sms'"
      :loading="loading"
      @change-step="onChangeStep"
    />
    <!-- /Billing Dialog SMS -->

    <!-- Change Plan Dialog -->
    <change-plan-dialog
      v-if="isPlanDiffDialogVisible"
      @close="closeDiffDialog"
    />
    <!-- /Change Plan Dialog -->

    <!-- Change Number Of Users Dialog -->
    <change-number-of-users-dialog
      v-if="isChangeNumberOfUsersDialogVisible"
      @close="closeChangeNumberOfUsersDialog"
    />
    <!-- /Change Number Of Users Dialog -->
  </adm-modal>
</template>

<script>
import AdmModal from '@/views/_components/modal/AdmModal'
import BillingDialogCheckout from '@/views/Dashboard/BillingDialog/Checkout/BillingDialogCheckout'
import BillingDialogDeactivate from '@/views/Dashboard/BillingDialog/Deactivate/BillingDialogDeactivate'
import BillingDialogFreeChosen from '@/views/Dashboard/BillingDialog/FreeChosen/BillingDialogFreeChosen'
import BillingDialogFreeDowngrade
  from '@/views/Dashboard/BillingDialog/FreeDowngrade/BillingDialogFreeDowngrade'
import BillingDialogPlans from '@/views/Dashboard/BillingDialog/Plans/BillingDialogPlans'
import BillingDialogSms from '@/views/Dashboard/BillingDialog/Sms/BillingDialogSms'
import mixinPlans from '@/mixins/plans/plans'
import mixinPrice from '@/mixins/common/price'
import { mapState } from 'vuex'
import ChangePlanDialog from './ChangePlanDialog/ChangePlanDialog'
import ChangeNumberOfUsersDialog
  from '@/views/Dashboard/BillingDialog/ChangeNumberOfUsersDialog/ChangeNumberOfUsersDialog'

export default {
  name: 'BillingDialog',

  components: {
    ChangeNumberOfUsersDialog,
    ChangePlanDialog,
    AdmModal,
    BillingDialogCheckout,
    BillingDialogDeactivate,
    BillingDialogFreeChosen,
    BillingDialogFreeDowngrade,
    BillingDialogPlans,
    BillingDialogSms,
  },

  mixins: [
    mixinPlans,
    mixinPrice
  ],

  data () {
    return {
      loading: true,
      paddleProducts: [],
      plans: [],
      itemsToDeactivate: {},
      isPlanDiffDialogVisible: false,
      isChangeNumberOfUsersDialogVisible: false,
    }
  },

  computed: {
    ...mapState({
      billingDialogStep: state => state.billing.billingDialogStep,
      canCloseBillingDialog: state => state.billing.canCloseBillingDialog,
      tenantInfo: state => state.billing.tenantInfo
    }),

    isFreeTrialExpired () {
      return this.$route.name !== 'login' ? this.$store.getters['billing/isFreeTrialExpired'] : false
    },

    isSubscriptionPaused () {
      return this.$route.name !== 'login' ? this.$store.getters['billing/isSubscriptionPaused'] : false
    },

    shouldDeactivateEntity () {
      return this.$route.name !== 'login' ? this.$store.getters['billing/shouldDeactivateEntity'] : false
    },

    count () {
      return this.$store.state.billing.visibleDialogBilling
    },

    toolbarMaxWidth () {
      const width = {
        plans: 1200,
        checkout: 1000,
        'free-downgrade': 368,
        'free-chosen': 368,
        deactivate: 484,
        sms: 484,
      }

      return width[this.billingDialogStep]
    },

    ...mapState({
      visibleDialogBilling: state => state.billing.visibleDialogBilling
    })
  },

  watch: {
    visibleDialogBilling (newValue, oldValue) {
      if (newValue !== oldValue && newValue === true) {
        this.onOpen()
      }
    }
  },

  created: function () {
    if (this.visibleDialogBilling === true) {
      this.onOpen()
    }
  },

  methods: {
    closeDialog () {
      if (this.canCloseBillingDialog === true) {
        this.$store.commit('billing/setDeactivateFeatureDialog', false)
        this.$store.commit('billing/setBillingDialogStep', 'plans')
        this.$store.commit('billing/setVisibleDialogBilling', false)
      }
    },

    async onOpen () {
      const canCloseBillingDialog = !(this.isFreeTrialExpired || this.isSubscriptionPaused || this.shouldDeactivateEntity)
      this.$store.commit('billing/setCanCloseBillingDialog', canCloseBillingDialog)

      if (this.billingDialogStep !== 'sms') {
        this.loading = true
        await this.getTenantInfo()
        await this.getPlans()
      }
    },

    async getTenantInfo () {
      const response = await this.$http.get(
        '/api/v1/backoffice/tenant-info'
      )

      this.$store.commit('billing/setTenantInfo', response.data.tenantInfo)
    },

    async getPrices () {
      const planIds = this.plans.map(value => value.paddlePlanId).filter(item => item);
      const planIdsForUsers = this.plans.map(value => value.paddlePlanIdForUser).filter(item => item);

      const response = await this.$jsonp(
        process.env.VUE_APP_PADDLE_SANDBOX_ENABLED === '1' ? 'https://sandbox-checkout.paddle.com/api/2.0/prices' : 'https://checkout.paddle.com/api/2.0/prices',
        { product_ids: planIds.concat(planIdsForUsers).join(', ') }
      )

      if (response.success === true && response.response.products.length > 0) {
        this.paddleProducts = response.response.products
        this.$store.commit('billing/setCurrency', response.response.products[0].currency)
        this.$store.commit('billing/setCurrencySymbol', this.getCurrencySymbolByCountryCode(response.response.products[0].currency))
      }
    },

    async getPlans () {
      const response = await this.$http.get('/api/v1/backoffice/plans')

      this.plans = response.data.plans

      await this.getPrices()

      // Set prices and coupons to plans
      for (let i = 0; i < this.plans.length; i++) {
        let planToSetPrice = this.paddleProducts.find(plan => plan.product_id === this.plans[i].paddlePlanId)

        if (planToSetPrice !== undefined) {
          this.plans[i].price = planToSetPrice.price
          this.plans[i].priceForUser = null

          let priceForUser = this.paddleProducts.find(plan => plan.product_id === this.plans[i].paddlePlanIdForUser)

          if (priceForUser) {
            this.plans[i].priceForUser = {
              price: this.getPriceForUser(this.plans[i], priceForUser.price),
              isThereATax: priceForUser.price.tax > 0
            }
          }
          this.plans[i].coupon = this.getPlanCoupons(this.plans[i])
        }

        if (this.plans[i].name === 'free_v2') {
          let priceForUser = this.paddleProducts.find(plan => plan.product_id === this.plans[i].paddlePlanIdForUser)
          this.plans[i].priceForUser = {
            price: this.getPriceForUser(this.plans[i], priceForUser.price),
            isThereATax: priceForUser.price.tax > 0
          }
        }
      }

      this.loading = false
    },

    getPriceForUser (plan, priceData) {
      return this.roundedToFixed(plan.billingInterval === 'monthly' ? priceData.net : priceData.net / 12, 2)
    },

    onChangePlan () {
      this.$store.commit('billing/setBillingDialogStep', 'plans')
      this.$store.commit('billing/setSelectedPlan', {})
    },

    onChangeStep (step) {
      if (step === 'change') {
        this.isPlanDiffDialogVisible = true

        return
      }

      if (step === 'changeNumberOfUsers') {
        this.isChangeNumberOfUsersDialogVisible = true

        return
      }

      this.isPlanDiffDialogVisible = false
      this.$store.commit('billing/setBillingDialogStep', step)
    },

    updateDeactivationItems (type, items) {
      this.itemsToDeactivate[type] = items
    },

    closeDiffDialog () {
      this.isPlanDiffDialogVisible = false
    },

    closeChangeNumberOfUsersDialog () {
      this.isChangeNumberOfUsersDialogVisible = false
    },

    roundedToFixed (amount, digits) {
      if (amount % 1 !== 0 && amount.toFixed(digits) % 1 !== 0) {
        return amount.toFixed(digits)
      }

      return amount.toFixed(0)
    },
  }
}
</script>

<style lang="scss">
// Billing Dialog
.ad-billing-dialog {

  .el-dialog__body {
    padding: 0 16px 24px;
  }

  & > .el-dialog__wrapper > .el-dialog {
    background: $shade-200 url('~@/assets/img/hero.svg') repeat-x top center;
    border-radius: 0;

    @include media-breakpoint-down($bp-large-max) {
      width: inherit;
    }
    @include media-breakpoint-down($bp-small-max) {
      width: inherit;
    }
  }

  // Toolbar
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    &__close {
      cursor: pointer;
      width: 40px;
      height: 40px;
      order: 2;

      path {
        fill: $shade-500;
      }
    }
  }
}
</style>
