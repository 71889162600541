<template>
  <adm-button icon-start="share" size="medium" class="adm-share-link-button" @click="$emit('click')">
    {{ $isGroupOneTenant() ? 'Add to website' : $t('share_booking') }}
  </adm-button>
</template>
<script>
import AdmButton from '../../../_components/button/AdmButton.vue'

export default {
  name: 'ShareLinkButton',
  components: { AdmButton },
}
</script>

<style lang="scss">
.adm-share-link-button.adm-button  {
  background-color: #8E42D1;
  margin-right: 24px;
  font-weight: 600;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;

  @include tablet-down {
    display: none;
  }

  .tz-promotions {
    font-size: 18px;
  }

  &.adm-button__size__medium.is-icon-start .adm-button__icon-wrapper {
    margin-right: 8px;
  }

  // Hover
  &:hover {
    background: #7235A7;
  }

  // Focus
  &:focus:not(:active), &.is-focused {
    background: #7235A7;
    border: 2px solid $adm-white;
    box-shadow: 0 0 0 2px #7235A7;
  }

  // Active
  &:active, &.is-active {
    background: #55287D;
  }

  // Disabled & Loading
  &.is-disabled, &.is-loading {
    background: #E8D9F6;
  }
}
</style>
