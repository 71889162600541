<template>
  <div class="dashboard-alerts">
    <!-- Impersonate ribbon -->
    <impersonate-ribbon v-if="$cookies.get('isImpersonated') === '1'" />
    <!-- /Impersonate ribbon -->

    <!-- Email Verification Alert -->
    <adm-alert-top
      v-if="isVisibleAlertForEmailVerification"
      type="warning"
      :title="$t('verification_link_warning', { email: $store.state.user.email })"
    >
      <template #right>
        <adm-button
          :loading="loadingResendVerification"
          size="mini"
          class="ml-4"
          type="ghost"
          color="dark"
          :class="{ 'adm-button--resend-disabled': isResendConfirmationEmailDisabled }"
          :disabled="isResendConfirmationEmailDisabled"
          @click="resendVerificationEmail"
        >
          {{ isResendConfirmationEmailDisabled === false ? $t('resend') : $t('resend_in', { time: formatSeconds(timeRemaining) }) }}
        </adm-button>
      </template>
    </adm-alert-top>
    <!-- /Email Verification Alert -->

    <!-- SMS credits run out -->
    <adm-alert-top
      v-if="isVisibleAlertSMSCreditRunningLow"
      type="danger"
      :title="$t('sms_credits_running_low')"
      closable
      @close="hideSMSCreditRunningLowAlert"
    >
      <template #right>
        <adm-button size="mini" class="ml-4" icon-start="plus" type="ghost" color="dark" @click="addCredits">
          {{ $t('add_credits') }}
        </adm-button>
      </template>
    </adm-alert-top>
    <!-- /SMS credits run out -->

    <!-- Expiring Free Trial Alert -->
    <adm-alert-top
      v-if="isVisibleAlertExpiringFreeTrial"
      type="danger"
      :title="$t('trial_expired_alert')"
      closable
      @close="hideExpireAlert"
    >
      <template #right>
        <adm-button size="mini" class="ml-4" type="ghost" color="dark" @click="showPlansDialog">
          {{ $t('view_plans') }}
        </adm-button>
      </template>
    </adm-alert-top>
    <!-- /Expiring Free Trial Alert -->

    <!-- Payment Rejected Alert -->
    <adm-alert-top
      v-if="isVisibleAlertPaymentRejected"
      type="danger"
      :title="$screen.aboveMobile ?
        $t('payment_was_unsuccessful_alert_1') + ' ' + $t('payment_was_unsuccessful_alert_1') + ' ' + formatDate(tenantInfo.subscription.nextBillDate) + '. ' + $t('payment_was_unsuccessful_alert_3') :
        $t('payment_was_unsuccessful_alert_1')"
    >
      <template #right>
        <adm-button size="mini" class="ml-4" type="ghost" color="dark" @click="changeBillingDetails">
          {{ $t('change_billing_details') }}
        </adm-button>
      </template>
    </adm-alert-top>
    <!-- /Payment Rejected Alert -->

    <!-- Christmas Promotion 2023 Tablet/Mobile -->
    <promotion2024
      v-if="isPromoBannerVisible"
      class="dashboard-alerts__promo-offer-mobile"
    />
    <!-- /Christmas Promotion 2023 Tablet/Mobile -->

    <!-- Global Error Messages Alert -->
    <div v-for="(globalErrorMessage, index) in visibleAlertGlobalErrorMessages" :key="index">
      <adm-alert-top
        v-if="globalErrorMessage === 'apple-calendar' && $store.getters['features/isFeatureEnabled']('apple_calendar')"
        type="danger"
        :title="$t('apple_calendar_connect_global_error')"
        closable
        @close="hideAppleCalendarGlobalErrorMessages(index)"
      >
        <template #right>
          <adm-button
            size="mini"
            class="ml-4"
            type="ghost"
            color="dark"
            @click="goToSetUp(globalErrorMessage)"
          >
            {{ $t('set_up') }}
          </adm-button>
        </template>
      </adm-alert-top>

      <adm-alert-top
        v-if="globalErrorMessage === 'smtp' && $store.getters['features/isFeatureEnabled']('smtp_email')"
        type="danger"
        :title="$t('smtp_has_stopped_working_please_configure_it_again')"
        closable
        @close="hideSmtpGlobalErrorMessages(index)"
      >
        <template #right>
          <adm-button
            size="mini"
            class="ml-4"
            type="ghost"
            color="dark"
            @click="goToSetUp(globalErrorMessage)"
          >
            {{ $t('set_up') }}
          </adm-button>
        </template>
      </adm-alert-top>
    </div>
    <!-- /Global Error Messages Alert -->

  </div>
</template>

<script>
import AdmAlertTop from '@/views/_components/alert/AdmAlertTop.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import { mapState } from 'vuex'
import mixinDateTime from '@/mixins/common/datetime'
import mixinSecurity from '@/mixins/security/security'
import ImpersonateRibbon from '@/views/Dashboard/_components/ImpersonateRibbon.vue'
import { isPwa } from '@/utils/isPwa'
import Promotion2024 from '@/views/Dashboard/Promotion2024/Promotion2024.vue'

export default {
  name: 'DashboardAlerts',

  components: {
    Promotion2024,
    ImpersonateRibbon,
    AdmAlertTop,
    AdmButton
  },

  mixins: [mixinDateTime, mixinSecurity],

  data () {
    return {
      loadingResendVerification: false,
      timeRemaining: 0,
      isResendConfirmationEmailDisabled: false,
      showPromoBannerBasedOnTime: false
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo,
    }),

    isPwa () {
      return isPwa()
    },

    isVisibleAlertForEmailVerification () {
      return this.$store.state.settings.email.isSuperAdminEmailVerified === false
    },

    isVisibleAlertSMSCreditRunningLow () {
      if (this.$store.getters['settings/isAgency'] && this.$store.state.settings.agency.isSmsNotificationsFeatureVisible === false) {
        return false
      }

      return this.$store.getters['settings/isSMSCreditRunningOutAndHaveRechargedSmsCredits']() && this.$store.state.settings.visibleSeenMSCreditRunningLowAlert
    },

    isVisibleAlertPaymentRejected () {
      return this.hasPermission('edit_billing_plan') &&
        this.tenantInfo &&
        this.tenantInfo.subscription &&
        this.tenantInfo.subscription.status === 'past_due'
    },

    isAlertVisible () {
      return this.isVisibleAlertExpiringFreeTrial === true
        || this.isVisibleAlertPaymentRejected === true
        || this.isVisibleAlertSMSCreditRunningLow === true
        || this.isVisibleAlertForEmailVerification === true
        || this.$cookies.get('isImpersonated') === '1'
        || this.visibleAlertGlobalErrorMessages === true
        || this.isPromoBannerVisible === true
    },

    isVisibleAlertExpiringFreeTrial () {
      return this.hasPermission('edit_billing_plan') && this.$store.state.billing.visibleExpireAlert
    },

    isPromoBannerVisible () {
      return !this.$store.getters['settings/isAgency']
        && this.showPromoBannerBasedOnTime
        && ['free', 'free_v2', 'free_trial'].includes(this.$store.state.billing?.tenantInfo?.plan?.name)
        && this.$store.state.settings.christmasPromo2023.showChristmasPromo2023Banner
        && !isPwa()
    },

    visibleAlertGlobalErrorMessages () {
      return this.$store.state.settings.globalErrorMessages.globalErrorMessages
    }
  },

  watch: {
    isAlertVisible: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => {
          this.setAlertHeight()
        })
      }
    },
  },

  created () {
    this.handleEmailVerification()

    const threeHoursFromRegistration = this.$moment(this.$store.state.settings.general.registrationDate).clone().add(3, 'hours');
    const currentTime = this.$moment();
    if (currentTime.isSameOrAfter(threeHoursFromRegistration)) {
      this.showPromoBannerBasedOnTime = true
    }
  },

  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.setAlertHeight);

      this.setAlertHeight()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setAlertHeight);
  },

  methods: {
    handleEmailVerification () {
      if (this.isVisibleAlertForEmailVerification) {
        this.$http.get('/api/v1/confirm-email/timer').then(({ data: { time } }) => {
          if (time) {
            this.isResendConfirmationEmailDisabled = true
            this.startTimer(time)
          }
        })
      }

      if (this.$route.query.emailVerificationStatus === 'alreadyVerified') {
        this.$message({ message: this.$t('email_address_is_already_verified'), type: 'error', showClose: true })

        this.$router.replace({ path: this.$route.path });
      }

      if (this.$route.query.emailVerificationStatus === 'verified') {
        this.$message({ message: this.$t('your_email_has_been_verified'), type: 'success', showClose: true })

        this.$router.replace({ path: this.$route.path });
      }
    },

    resendVerificationEmail () {
      this.loadingResendVerification = true
      this.$http.post('/api/v1/confirm-email/resend-verification-email').then(() => {
        this.$message({
          message: this.$t('verification_link_sent', { email: this.$store.getters['user/email'] }),
          type: 'success',
          dangerouslyUseHTMLString: true,
          showClose: true
        })

        this.isResendConfirmationEmailDisabled = true
        this.startTimer(300)
      }).catch((e) => {
        if (e.response.status === 422) {
          this.$message({
            message: e.response.data.message,
            type: 'error',
            showClose: true
          })
        }
      }).finally(() => {
        this.loadingResendVerification = false
      })
    },

    setAlertHeight () {
      const dashboardAlerts = document.querySelector('.dashboard-alerts')
      let childElementsHeight = 0
      if (dashboardAlerts) {
        const getHeight = () => Array.from(dashboardAlerts.children).reduce((acc, child) => acc + child.offsetHeight, 0)
        childElementsHeight = getHeight()

        setTimeout(() => {
          this.$store.commit('dashboardAlerts/setAlertHeight', getHeight())
        }, 200)
      }

      this.$store.commit('dashboardAlerts/setAlertHeight', childElementsHeight)
    },

    hideExpireAlert () {
      this.$store.commit('billing/setVisibleExpireAlert', false)
      this.$store.commit('billing/setWasSeenExpireAlert', true)
    },

    hideSmtpGlobalErrorMessages (index) {
      this.visibleAlertGlobalErrorMessages.splice(index, 1)
      this.$store.state.settings.globalErrorMessages.globalErrorMessages = this.visibleAlertGlobalErrorMessages
    },

    hideAppleCalendarGlobalErrorMessages (index) {
      this.visibleAlertGlobalErrorMessages.splice(index, 1)
      this.$store.state.settings.globalErrorMessages.globalErrorMessages = this.visibleAlertGlobalErrorMessages
      this.$http.post('/api/v1/settings/global-error-messages', { globalErrorMessages: this.visibleAlertGlobalErrorMessages })
    },

    changeBillingDetails () {
      window.open(this.tenantInfo.subscription.updateUrl, '_blank')
    },

    addCredits () {
      this.$store.commit('billing/setBillingDialogStep', 'sms')
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },

    hideSMSCreditRunningLowAlert () {
      this.$store.commit('settings/setVisibleSMSCreditRunningLowAlert', false)
      this.$http.post('/api/v1/settings/banner', ['true'])
    },

    showPlansDialog () {
      this.$store.commit('billing/setBillingDialogStep', 'plans')
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },

    startTimer (time) {
      this.timeRemaining = time
      const intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.isResendConfirmationEmailDisabled = false
          this.isExpired = true;
          clearInterval(intervalId);
        }
      }, 1000);
    },

    formatSeconds (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },

    goToSetUp (globalErrorMessage) {
      this.$router.push({ path: globalErrorMessage === 'apple-calendar' ? '/features/integrations/' + globalErrorMessage : '/features/' + globalErrorMessage })
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-alerts {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;

  &__promo-offer-mobile {
    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  .adm-button--resend-disabled {
    min-width: 110px;
  }
}
</style>
